import React from 'react'

import { Link } from 'gatsby'
import Logo from './logo'
import Navigation from './navigation'
import styles from './header.module.css'

function Header({ location, packageTypes, photoshoots }) {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logo}>
        <Logo />
      </Link>

      <Navigation
        pathname={location.pathname}
        packageTypes={packageTypes}
        photoshoots={photoshoots}
      />
    </header>
  )
}

export default Header
