import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import cls from 'classnames'

export default ({ pathname, packageTypes, photoshoots }) => (
  <nav role="navigation">
    <ul className={styles.navigation}>
      <li
        className={cls(styles.homeNav, styles.navigationItem, {
          [styles.navSelected]: pathname === '/',
        })}
      >
        <Link to="/">Home.</Link>
      </li>
      <li
        className={cls(styles.navigationItem, {
          [styles.navSelected]: pathname === '/about/',
        })}
      >
        <Link to="/about/">About.</Link>
      </li>

      <li
        className={cls(styles.navigationItem, {
          [styles.navSelected]: pathname.includes('-photoshoot'),
        })}
      >
        Photoshoots.
        <ul className={styles.dropdown}>
          <li>
            <Link to="/christmas">🎄 Christmas 🎄</Link>
          </li>

          {photoshoots.items.map(({ name, slug }) => (
            <li key={slug}>
              <Link to={`/${slug}`}>{name}</Link>
            </li>
          ))}
        </ul>
      </li>

      <li
        className={cls(styles.navigationItem, {
          [styles.navSelected]: pathname === '/contact/',
        })}
      >
        <Link to="/contact/">Contact.</Link>
      </li>
    </ul>
  </nav>
)
